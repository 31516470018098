import { TFunction } from 'i18next';

export const knowledgeMarks: { [index: string]: string } = {
  1: 'beginner',
  2: 'middle',
  3: 'senior',
};

export const levelMarks: { [index: string]: string } = {
  1: 'low',
  2: 'med',
  3: 'high',
};

export const webinarDuration: { [index: string]: string } = {
  30: '0:30',
  45: '',
  60: '1:00',
  75: '',
  90: '1:30',
  105: '',
  120: '2:00',
};

export const studyLoad: { [index: string]: string } = {
  2: '2',
  4: '4',
  6: '6',
  8: '8',
  10: '10',
};

export const levelMarksView = (t: TFunction) => {
  return {
    1: t('settingsForm.knowledgeMarks.low'),
    2: t('settingsForm.knowledgeMarks.med'),
    3: t('settingsForm.knowledgeMarks.high'),
  };
};

export const practiceMarksView = (t: TFunction) => {
  return {
    1: t('settingsForm.practiceMarks.low'),
    2: t('settingsForm.practiceMarks.med'),
    3: t('settingsForm.practiceMarks.high'),
  };
};

export const weeksMarksShort: { [index: string]: string } = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
};

export const weeksMarks: { [index: string]: string } = {
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
};
